<template>
  <h2>Настройка окружения</h2>
  <p>Для того, чтобы начать решать задачки с этого сайта, нужно настроить рабочее окружение. Как это сделать объясняю в видео:</p>
  <div class="iframe-16-9">
    <iframe src="https://www.youtube.com/embed/7DrzBzw4J-k" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
  </div>
  <p>Код файла .eslintrc.js представлен ниже:</p>
  <Code lang="js" :code="`
    module.exports = {
        env: {
            browser: true,
            es2020: true
        },
        extends: 'standard',
        parserOptions: {
            ecmaVersion: 11
        },
        rules: {
            indent: [
                'error',
                4
            ],
            'brace-style': [
                'error',
                'stroustrup'
            ],
            'linebreak-style': [
                'error',
                'windows'
            ],
            'space-before-function-paren': [
                'error',
                'never'
            ],
            quotes: [
                'error',
                'single'
            ],
            semi: [
                'error',
                'never'
            ],
            'no-unused-vars': [
                'error',
                { varsIgnorePattern: '^task' }
            ]
        }
    }

  `"
  />
</template>

<script>
import Code from '@/components/ui/Code'

export default {
  components: {
    Code
  },
  props: ['task']
}
</script>
